<template>
  <div class="admin-incident-type-show">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Loading -->
    <div v-if="isLoading" class="text-center loading-wrapper">
      <a-spin />
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="page-title">
          <a-button
            type="default"
            style="display: inline; margin-right: 15px"
            @click.prevent="$router.push('/admin/settings/incident-types')"
            icon="left"
          ></a-button>

          <admin-org-indicator></admin-org-indicator>

          {{ title }}
        </h1>
        <div class="actions">
          <a-button
            class="button-margin-left btn-rounded"
            icon="edit"
            size="large"
            type="primary"
            @click.prevent="edit"
            >Edit</a-button
          >

          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="attemptDelete"
          >
            <a-button
              class="button-margin-left btn-rounded"
              icon="delete"
              size="large"
              type="danger"
              >Delete</a-button
            >
          </a-popconfirm>
        </div>
      </div>
      <!-- / Page Header -->

      <!-- Tabs -->
      <a-tabs v-model="selectedTab">
        <!-- <a-tab-pane tab="Details" key="details"></a-tab-pane> -->
        <a-tab-pane tab="Tasks" key="tasks"></a-tab-pane>
      </a-tabs>
      <!-- / Tabs -->

      <!-- Content -->
      <div class="content">
        <div class="tasks" v-if="selectedTab == 'tasks'">
          <incident-type-tasks
            :incident-type="incidentType"
            :org="selectedOrganisation"
            :tenant-id="tenantId"
          ></incident-type-tasks>
        </div>
      </div>
      <!-- / Content -->
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import incidentTypes from "../../../../api/incident-types";
import IncidentTypeTasks from "./Show/Tabs/IncidentTypeTasks.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoadingIncidentType: false,
      incidentType: null,
      selectedTab: "tasks",
      isDeleting: false
    };
  },
  components: { LoadingScreen, IncidentTypeTasks },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),

    isLoading() {
      return this.isLoadingIncidentType;
    },

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.incidentType && this.incidentType.displayName) {
        return this.incidentType.displayName;
      }
      return "Unknown";
    },
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadIncidentType();
    }
  },
  methods: {
    loadIncidentType() {
      let vm = this;
      vm.isLoadingIncidentType = true;
      vm.incidentType = null;
      incidentTypes
        .getIncidentType(this.tenantId, this.$route.params.id)
        .then((r) => {
          vm.isLoadingIncidentType = false;
          vm.incidentType = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingIncidentType = false;
          vm.$message.error("Incident type not found");
          vm.$router.push("/admin/settings/incident-types");
        });
    },

    edit() {
      this.$router.push(
        "/admin/settings/incident-types/" + this.incidentType.id + "/edit"
      );
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      incidentTypes
        .deleteIncidentType(this.tenantId, this.$route.params.id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Incident type deleted successfully");
          vm.$router.push("/admin/settings/incident-types");
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$message.error("Error deleting incident type");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.button-margin-left {
  margin-left: 10px;
}
</style>