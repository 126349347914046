<template>
  <div class="incident-type-tasks">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- No tasks -->
    <a-alert
      v-if="!isLoading && tasks.length == 0"
      type="info"
      message="No tasks to show"
    ></a-alert>
    <!-- / No tasks -->

    <!-- Tasks -->
    <div class="tasks" v-if="!isLoading">
      <div
        class="
          ant-table
          ant-table-scroll-position-left
          ant-table-default
          ant-table-bordered
        "
      >
        <div class="ant-table-content">
          <div class="ant-table-body">
            <table v-if="tasks.length" class="">
              <thead class="ant-table-thead">
                <tr>
                  <th>Order</th>
                  <th>Checklist Task</th>
                  <th>Skill</th>
                  <th>Reminder / Late</th>
                  <th style="width: 230px">Actions</th>
                </tr>
              </thead>
              <draggable
                tag="tbody"
                class="ant-table-tbody"
                v-model="tasksForTable"
                handle=".handle"
              >
                <tr
                  class="ant-table-row ant-table-row-level-0"
                  v-for="(task, i) in tasksForTable"
                  :key="`row-${i}`"
                >
                  <td>
                    <a-icon
                      type="menu"
                      class="handle"
                      style="margin-right: 15px; cursor: move;"
                    />
                    {{ task.order }}
                  </td>
                  <td>{{ task.displayName }}</td>
                  <td>{{ task.skill }}</td>
                  <td>{{ task.reminderLate }}</td>
                  <td>
                    <div class="table-actions-wrap">
                      <edit-task-button-and-modal
                        @task-updated="fetch"
                        :tenant-id="tenantId"
                        :org="org"
                        :incident-type="incidentType"
                        :task="task"
                      ></edit-task-button-and-modal>

                      <a-popconfirm
                        style="margin-left: 10px"
                        title="Are you sure?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="() => attemptDelete(task.id)"
                      >
                        <a-button icon="delete" type="danger">Delete</a-button>
                      </a-popconfirm>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </div>

      <div class="bottom-actions-wrapper" v-if="canEdit">
        <add-task-button-and-modal
          @task-added="fetch"
          :order="nextOrderValue"
          :tenant-id="tenantId"
          :org="org"
          :incident-type="incidentType"
        ></add-task-button-and-modal>
      </div>
    </div>
    <!-- / Tasks -->
  </div>
</template>

<script>
import checklistTasks from "../../../../../../api/checklist-tasks";
import AddTaskButtonAndModal from "./IncidentTypeTasks/AddTaskButtonAndModal.vue";
import EditTaskButtonAndModal from "./IncidentTypeTasks/EditTaskButtonAndModal.vue";
const _ = require("lodash");
import LoadingScreen from "../../../../../../components/LoadingScreen.vue";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import axios from "axios";

export default {
  props: ["incidentType", "org", "tenantId"], 
   components: {
    AddTaskButtonAndModal,
    EditTaskButtonAndModal,
    LoadingScreen,
    draggable,
  },
  data() {
    return {
      isDeleting: false,
      isLoading: false,
      tasks: [],
      canEdit: true
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      checklistTasks
        .getChecklistTasks(this.tenantId, this.incidentType.checklistId)
        .then((r) => {
          vm.isLoading = false;
          vm.tasks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading incident type tasks");
        });
    },

    attemptDelete(id) {
      let vm = this;
      vm.isDeleting = true;
      checklistTasks
        .deleteChecklistTask(this.tenantId, this.incidentType.checklistId, id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Incident type deleted successfully");
          vm.fetch();
        })
        .catch((e) => {
          vm.isDeleting = false;
          vm.$message.error("Error deleting incident type");
          console.log(e);
        });
    },

    reorderTasks(tasksNewOrder) {
      let vm = this;

      let newOrders = []; // map of (id, order)
      _.each(tasksNewOrder, (task, i) => {
        newOrders.push({
          id: task.id,
          order: i,
        });
      });

      // Update locally
      this.tasks = _.map(this.tasks, (task) => {
        let newOrder = _.find(newOrders, (newOrder) => {
          return newOrder.id == task.id;
        });
        if (newOrder) {
          return {
            ...task,
            order: newOrder.order,
          };
        } else {
          return task;
        }
      });

      // Update server
      let requests = [];
      _.each(newOrders, (newOrder) => {
        let task = _.find(this.tasks, (task) => {
          return task.id == newOrder.id;
        });
        requests.push(
          checklistTasks.updateChecklistTask(this.tenantId, {
            ...task,
            order: task.order,
          })
        );
      });
      axios
        .all(requests)
        .then(() => {
          vm.$message.success("Tasks reordered successfully");
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error reordering tasks");
        });
    },
  },
  computed: {
    ...mapGetters("admin", {
      skills: "skills",
    }),

    tasksOrdered() {
      return _.orderBy(this.tasks, "order");
    },

    tasksForTable: {
      get() {
        return _.map(this.tasksOrdered, (task) => {
          let t = { ...task };

          if (t.skillId) {
            let skill = _.find(this.skills, { id: t.skillId });
            t.skill = skill ? skill.displayName : "Unknown";
          } else {
            t.skill = null;
          }

          t.orderToShow = t.order + 1;
          t.reminderLate =
            (t.slaRemindHours !== undefined ? t.slaRemindHours + "h" : "-") +
            " / " +
            (t.slaLateHours !== undefined ? t.slaLateHours + "h" : "-");

          return t;
        });
      },
      set(val) {
        this.reorderTasks(val);
      },
    },

    nextOrderValue() {
      let val = 0;
      if (this.tasks && this.tasks.length) {
        let orders = _.map(this.tasks, "order");
        val = _.max(orders) + 1;
      }
      return val;
    },

    columns() {
      let cols = [
        {
          title: "Order",
          dataIndex: "orderToShow",
        },
        {
          title: "Checklist Task",
          dataIndex: "displayName",
        },
        {
          title: "Skill",
          dataIndex: "skill",
        },
        {
          title: "Reminder / Late",
          dataIndex: "reminderLate",
        },
      ];

      if (this.canEdit) {
        cols.push({
          title: "Actions",
          width: 230,
          dataIndex: "actions",
          scopedSlots: { customRender: "action" },
        });
      }

      return cols;
    },
  },
};
</script>

<style lang="scss">
.incident-type-tasks {
  .loader {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ant-table {
    background: #fff !important;
  }

  .incident-type-task-list-item {
    margin-bottom: 15px;
  }

  .bottom-actions-wrapper {
    padding-top: 20px;
  }
}
</style>